body {
  margin: 0;
  height: 100vh;
  font-family: 'Roboto', 'Arial', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

a {
  text-decoration: none;
  transition: 0.2s;
}

main {
  align-self: center;
}

main a {
  color: #0165a7;
}

main a:hover, main a:focus {
  color: #075488;
}

footer {
  background-color: #0d2a3a;
  font-size: 13px;
}

footer a {
  color: #d9e1e2;
  margin: 1.5rem;
}
.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.icons img {
  height: 24px;
  width: auto;
}

footer a:hover, footer a:focus {
  color: #ffffff;
}

header {
  background-color: #003b5c;
}

